export const environment = {
  production: false,
  enableDebuggingTools: true,
  enableImpersonation: true,
  enableAPIVersioning: false,
  apiVersion: "v1",
  baseURL: "https://test.lostalker.com",
  apiIdentifier: "api://d73edfd7-55e9-4b38-ae45-d9414be89dc0",
  spaClientId: "07802da3-d7b0-4e24-a97a-a2e4f1f92ecd",
  azureTenantId: "0bf31a5e-7914-4ea5-b3d0-5832f9d20378",
  environmentName: "Test"
};
