<div class="d-flex container-fluid m-1">
  <div class="row">
    <div class="col-3 footer-image offset-lg-1 offset-md-1">
      <a href="https://www.simplenexus.com/">
        <img class="img-fluid" src="/assets/images/ncino-logo-mono-blue-RGB-Trimmed.png" alt="SimpleNexus Logo" />
      </a>
    </div>
    <div class="col-8 footer-text align-self-end">
      <span>LOS Talker Server v1.48.5.152test-ncino - &copy; 2023 nCino, All Rights Reserved</span>
    </div>
  </div>

</div>
